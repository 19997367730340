import Link from "gatsby-link";
import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { ACSS, H4, H5, P, Small } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import BlobComputer from "../../static/images/blob-computer.svg";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import UpsolveSearchBar from "../components/search/UpsolveSearchBar";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "SearchPage",
  pageComponentVersion: 0,
};

function getSearchParam() {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search).get("q");
  }
  return "";
}

/**
 * SearchPage
 * - v0: Simple results list
 *
 * @component
 * @version 0
 */
const SearchPage = (props) => {
  const [results, setResults] = useState([]);
  const [resultsCount, setResultsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  function searchSite(newSearchQuery) {
    // Prevent submission if it's the same query
    if (newSearchQuery === searchQuery) return;
    setIsProcessing(true);
    axios
      .get(`${UPSOLVE_API_URL}/v1/search/site?q=${newSearchQuery}`)
      .then(({ data }) => {
        setResults(data.results);
        setResultsCount(data.resultsCount);
        setSearchQuery(newSearchQuery);
        setIsProcessing(false);
      })
      .catch(console.warn);
  }

  // On Mount
  const trackingPageContext = { contentGroup, ...templateProps };
  useEffect(() => {
    // - track
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
    // - search if we have a query param
    if (getSearchParam()) searchSite(getSearchParam());
  }, []);

  return (
    <TopLevelLayout>
      <div className="index-container">
        <SEO
          title="Search | Upsolve"
          description="Find answers to all of your questions about bankruptcy."
          image="https://upsolve.org/images/meta-legalaid.png"
          url="https://upsolve.org/search/"
          jsonLD={[
            {
              "@context": "http://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Search",
                  item: "https://upsolve.org/search/",
                },
              ],
            },
          ]}
        />
        <MainHeader />
        <main>
          <StyledSearchPage>
            <div className="search__header">
              <div className="search__bar">
                <UpsolveSearchBar
                  autoFocus={true}
                  defaultValue={getSearchParam()}
                  onSearch={(newSearchQuery) => searchSite(newSearchQuery)}
                />
                <P>
                  <Small>
                    <b>Showing top {results.length} results</b> of {resultsCount} attorney articles
                  </Small>
                </P>
              </div>
              <div className="search__image">
                <BlobComputer />
              </div>
            </div>
            <div className="search__results">
              {isProcessing && <H5>Searching our content...</H5>}
              {!isProcessing &&
                (results || []).map(({ data }) => (
                  <Link key={data.path} to={data.path}>
                    <StyledSearchPageResult>
                      <div>
                        <H4>{data.title}</H4>
                      </div>
                      <div>
                        <P>{data.snippet}</P>
                      </div>
                      <div>
                        <Link to={data.path}>Read More →</Link>
                      </div>
                    </StyledSearchPageResult>
                  </Link>
                ))}
              {!results.length && !isProcessing && (
                <H5>
                  Enter your question in the search bar above!
                  <br />
                  {searchQuery && !results.length ? (
                    <>
                      If you're not finding articles, try out our <Link to="/learn/">Learning Center</Link>
                    </>
                  ) : (
                    ""
                  )}
                </H5>
              )}
            </div>
          </StyledSearchPage>
        </main>
        <MainFooter />
      </div>
    </TopLevelLayout>
  );
};

const StyledSearchPageResult = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.effects.shadow[350]};
  border: 1px solid ${(props) => props.theme.colors.white[700]};
  width: 100%;
  & > div {
    padding: 0.5em 2em;
    &:first-of-type {
      padding-top: 1.5em;
      border-bottom: 1px solid ${(props) => props.theme.colors.white[500]};
      h4 {
        color: ${(props) => props.theme.colors.brand[500]};
        line-height: 150%;
      }
    }
    &:last-of-type {
      padding-bottom: 1.5em;
    }
    p {
      margin: 1em 0 0;
    }
    a {
      ${ACSS}
    }
  }
`;

const StyledSearchPage = styled.div`
  min-height: 720px;
  .search__header {
    background: ${(props) => props.theme.colors.white[800]};
    border-bottom: 1px solid ${(props) => props.theme.colors.white[500]};
    & > div {
      width: 100%;
      max-width: 960px;
      margin: 0 auto;
      padding: 0 1em;
      small {
        color: ${(props) => props.theme.colors.gray[900]};
      }
    }
    .search__bar {
      padding: 2em 1em 0;
    }
    .search__image {
      text-align: center;
      width: 100%;
      height: 150px;
      overflow: hidden;
      margin: -2em auto 0;
      svg {
        height: 280px;
      }
    }
  }
  .search__results {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > h5,
    & > h5 > a {
      text-align: center;
      color: ${(props) => props.theme.colors.white[100]};
      a {
        text-decoration: underline;
      }
    }
    & > a {
      width: 100%;
      margin: 1em 2em;
    }
    @media (max-width: 45em) {
      padding: 1em;
      & > a {
        margin: 1em;
      }
    }
  }
`;

export default SearchPage;
